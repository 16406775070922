import ApiService from "@/common/api/api.service"

let apiBasePath = 'GrupoTurma'
const GruposTurmaService = {
    async obterPorId (grupoTurmaId) {
        let { data } = await ApiService.get(`${apiBasePath}/${grupoTurmaId}`)
        return data
    },
    async obterTodos (filtro) {
        let { data } = await ApiService.get(`${apiBasePath}/obter-por-curso`,
            {
                params: {
                    cursoId: filtro.cursoId
                }
            })
        return data
    },
    async listarTodos() {
        let { data } = await ApiService.get(`${apiBasePath}`)
        return data
    },
    // FUNÇÕES CRUD:
    async criar (form) {
        let { data } = await ApiService.post(`${apiBasePath}`, form)
        return data
    },
    async editar (form) {
        let { data } = await ApiService.put(`${apiBasePath}`, form)
        return data
    },
    async excluir (aulaId) {
        let { data } = await ApiService.delete(`${apiBasePath}/${aulaId}`)
        return data
    }
}

export default GruposTurmaService;