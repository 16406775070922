<template>
  <div>
    <titulo-relatorio-disciplinas />
    <b-row>
      <b-col cols="12" md="2" class="mt-3">
        <input-select-multiple
          ref="anoLetivoId"
          v-model="selecionados.anoLetivoId"
          :label="$t('ACADEMICO.ANO_LETIVO')"
          :options="opcoes.anosLetivos"
          required
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-3">
        <input-select-multiple
          ref="cursoId"
          v-model="selecionados.cursoId"
          :label="$t('ACADEMICO.CURSO')"
          :options="opcoes.cursos"
          :disabled="desabilitar.curso"
        />
      </b-col>
      <b-col cols="12" md="3" class="mt-3">
        <input-select-multiple
          ref="grupoTurmaId"
          v-model="selecionados.grupoTurmaId"
          :label="$t('ACADEMICO.GRUPO_TURMA')"
          :options="opcoes.gruposTurma"
          :disabled="desabilitar.grupoTurma"
        />
      </b-col>

      <b-col cols="12" md="4" class="mt-3">
        <input-select-multiple
          ref="turmaId"
          v-model="selecionados.turmaId"
          :label="$t('ACADEMICO.TURMA')"
          :options="opcoes.turmas"
          :disabled="desabilitar.turma"
        />
      </b-col>
      <b-col cols="12" md="auto" class="mt-3 mt-md-0">
        <b-button
          class="botao-acao-filtro w-100"
          variant="info"
          @click="realizarImpressao"
        >
          {{ $t('GERAL.GERAR_RELATORIO') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import pdf from '@/common/utils/pdf';
import helpers from '@/common/utils/helpers';
import mensagem from '@/common/utils/mensagem';

// Services:
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import CursoService from '@/common/services/academico/curso.service';
import GrupoTurmaService from '@/common/services/academico/gruposTurma.service';
import TurmaService from '@/common/services/academico/turma.service';
import DiscipinasService from '@/common/services/academico/disciplinas.service';

// Components:
import { InputSelectMultiple } from '@/components/inputs';
import TituloRelatorioDisciplinas from '@/views/academico/relatorios/relatorio-disciplinas/TituloRelatorioDisciplinas';
export default {
  components: {
    TituloRelatorioDisciplinas,
    InputSelectMultiple,
  },
  data() {
    return {
      selecionados: {
        anoLetivoId: null,
        cursoId: null,
        grupoTurmaId: null,
        turmaId: null,
      },
      opcoes: {
        anosLetivos: [],
        cursos: [],
        gruposTurma: [],
        turmas: [],
      },
      desabilitar: {
        curso: true,
        grupoTurma: true,
        turma: true,
      },
    };
  },
  watch: {
    'selecionados.anoLetivoId'(anoLetivoId) {
      if (anoLetivoId) {
        this.getCursos(anoLetivoId.id);
      } else {
        this.opcoes.cursos = [];
        this.opcoes.gruposTurma = [];
        this.opcoes.turmas = [];
        this.desabilitar.curso = true;
        this.desabilitar.grupoTurma = true;
        this.desabilitar.turma = true;
      }
      this.selecionados.cursoId = null;
      this.selecionados.grupoTurmaId = null;
      this.selecionados.turmaId = null;
    },
    'selecionados.cursoId': {
      handler(novoCursoId) {
        if (novoCursoId) {
          this.getGruposTurmaPorCursoId(novoCursoId);
        } else {
          this.desabilitar.grupoTurma = true;
          this.desabilitar.turma = true;
          this.opcoes.gruposTurma = [];
          this.opcoes.turmas = [];
        }
        this.selecionados.grupoTurmaId = null;
        this.selecionados.turmaId = null;
      },
    },
    'selecionados.grupoTurmaId': {
      handler(turmaId) {
        if (turmaId) {
          this.getTurmaPorGrupoTurmaIdAnoLetivoId();
        } else {
          this.desabilitar.turma = true;
          this.opcoes.turmas = [];
        }
        this.selecionados.turmaId = null;
      },
    },
    // 'selecionados.grupoTurmaId': 'getTurmaPorGrupoTurmaIdAnoLetivoId',
    // 'selecionados.cursoId': 'getGruposTurmaPorCursoId',
    // 'selecionados.anoLetivoId': [
    //   'getCursos',
    //   'getTurmaPorGrupoTurmaIdAnoLetivoId',
    // ],
  },
  mounted() {
    this.getAnosLetivos();
  },
  methods: {
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    realizarImpressao() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);

      const { anoLetivoId, grupoTurmaId, turmaId } = this.selecionados;

      const objetoParaFiltro = {
        anoLetivoId: anoLetivoId.id,
        grupoTurmaId: grupoTurmaId?.id,
        turmaId: turmaId?.id,
      };

      DiscipinasService.realizarImpressaoRelatorioDisciplinas(objetoParaFiltro)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            let blob = new Blob([bytes], { type: 'application/pdf' });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getAnosLetivos() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar()
        .then(({ data }) => {
          data = data.map((row) => {
            return {
              ...row,
              text: row.ano,
              value: row.id,
            };
          });

          this.opcoes.anosLetivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getCursos(anoLetivoId) {
      this.$store.dispatch(START_LOADING);
      CursoService.obterPorAnoLetivo(anoLetivoId)
        .then(({ data }) => {
          data = data.map((row) => {
            return {
              ...row,
              text: row.descricao,
              value: row.id,
            };
          });

          this.opcoes.cursos = data;
          this.desabilitar.curso = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getGruposTurmaPorCursoId(curso) {
      if (this.selecionados.grupoTurmaId !== null) {
        this.selecionados.grupoTurmaId = null;
        this.selecionados.turmaId = null;

        this.desabilitar.turma = true;
      }

      this.$store.dispatch(START_LOADING);
      GrupoTurmaService.obterTodos({ cursoId: curso.id })
        .then(({ data }) => {
          data = data.map((row) => {
            return {
              ...row,
              text: row.descricao,
              value: row.id,
            };
          });

          this.opcoes.gruposTurma = data;
          this.desabilitar.grupoTurma = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getTurmaPorGrupoTurmaIdAnoLetivoId() {
      if (this.selecionados.turmaId !== null) {
        this.selecionados.turmaId.value = '';
        this.selecionados.turmaId.text = '';
      }
      const anoLetivoId = this.selecionados.anoLetivoId?.id;
      const grupoTurmaId = this.selecionados.grupoTurmaId?.id;

      if (anoLetivoId !== null && grupoTurmaId !== undefined) {
        this.desabilitar.turma = false;
      }

      this.$store.dispatch(START_LOADING);
      TurmaService.buscarPorGrupoTurmaIdAnoLetivoId({
        grupoTurmaId: this.selecionados.grupoTurmaId?.id,
        anoLetivoId: this.selecionados.anoLetivoId?.id,
      })
        .then(({ data }) => {
          data = data.map((row) => {
            return {
              ...row,
              text: row.descricao,
              value: row.id,
            };
          });

          this.opcoes.turmas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>

<style>
.ajuste-fino-btn {
  margin-top: 45px;
}
</style>
